import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.de.mdx";
import Layout from "../../../components/blog/layout.de";
export const query = graphql`
  {
    mdx(
      slug: { eq: "kalorienzaehler-kalorien-je-portion-berechnen/index.de" }
    ) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        image_fourth {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_fourth_alt
        date(formatString: "D. MMMM YYYY", locale: "de-DE")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const AppImage = makeShortcode("AppImage");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Auch wenn uns Kalorientracker
  Apps das Leben deutlich erleichtert haben, können sie dennoch ziemlich lästig
  sein. Nach einem langen Arbeitstag und absolviertem Training möchtest du dich
  am liebsten auf die Coach legen und endlich vor dem Fernseher zur Ruhe kommen.
  Doch irgendetwas hast du vergessen, richtig die letzten Mahlzeiten müssen noch
  getrackt werden. Waren es jetzt 50g oder doch 60g Haferflocken nach dem
  Workout? Kommt dir diese Situation bekommt vor? Wir stellen dir deshalb ein
  weiteres Feature vor, mit dem du dir viel Frust ersparen kannst, indem du nur
  noch die Anzahl der Portionen eines Lebensmittels angeben musst, um deine
  Trackingeinträge zu erstellen.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Echte individuelle Portionsangaben`}</h2>
    <p>
  Viele Kalorientracker haben bei der Nahrungsmittelsuche Portionsangaben
  hinterlegt, teilweise mehrere unterschiedliche. Doch handelt es sich hierbei
  um Daten, die von anderen Nutzern hinterlegt wurden. Grundsätzlich können hier
  also beliebige und nicht plausible Daten hinterlegt sein. Auch können diese
  Angaben aufgrund ihres immensen Umfangs nicht händisch überprüft werden. Das
  führt dazu, dass viele Trainierende und Abnehmwillige ihren Erfolg teilweise
  willkürlichen Angaben anvertrauen. Und tatsächlich ist dies in vielen Fällen
  der Grund dafür, dass eine Diät scheitert. Ist es deshalb nicht naheliegend
  einem Nutzer die Freiheit zu geben, eigene individuelle Portionsgrößen zu
  Lebensmitteln zu hinterlegen? Für uns steht der Erfolg unserer Nutzer an
  erster Stelle, weshalb wir dir genau diese Freiheit geben.
    </p>
    <p>
  Wie in der unteren Abbildung zu sehen, musst du dafür unterhalb der
  Lebensmittelzusammenfassung einfach auf den Button "Portionsgröße" klicken, um
  in die dafür vorgesehene Eingabemaske zu navigieren.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  Im darauf folgenden Interface gibst du einfach nur noch die Portionsangabe des
  Lebensmittels an. Auch kannst du einfach zwischen der Eingabe in Gramm und
  Unzen wechseln. Nach einem Klick auf "Hinzufügen" wird die Portionsgröße auf
  deinem Gerät gespeichert und du navigierst automatisch zur nächsten
  Eingabemaske für die Anzahl der Portionen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  Falls du ein Lebensmittel bereits für Mahlzeiteneinträge genutzt hast, wird
  dir alternativ eine Eingabemaske wie die folgende angezeigt. Hier siehst du
  deine zuletzt verwendete Mengenangabe. Entweder benutzt du diese direkt für
  weitere Mahlzeiteneinträge, oder du verwandelst sie einfach mit einem Klick
  auf "Portionsgröße" in eine zum Nahrungsmittel gehörende Portionsangabe.
  Kalorientracken war noch nie einfacher!
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  Deine Portionsgröße wurde erfolgreich dem Nahrungsmittel zugewiesen. Jetzt
  musst du in Zukunft nur noch die Anzahl der Portionen eingeben, um effizient
  Mahlzeiteneinträge zu erstellen. In Zukunft brauchst du dir also keine
  Mengenangaben deiner Nahrung zu merken, um Einträge in deinem Kalorientracker
  zu erstellen.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_fourth)} imageAlt={props.data.mdx.frontmatter.image_fourth_alt} mdxType="AppImage" />
    <h2>{`Geh auf Nummer sicher!`}</h2>
    <p>
  Die Ernährung kann optimal geplant und das Training konsequent absolviert
  werden. Wenn du aber am Ende deine Ernährung falsch trackst, ist dein Vorhaben
  zum Scheitern verurteilt. Vetrau deshalb nicht auf willkürliche und nicht
  überprüfbare Portionsangaben in Lebensmitteldatenbanken. Die Anzahl der
  Lebensmittel, die du isst sind überschaubar, weshalb du einmalig
  Portionsgrößen hinterlegen solltest, um in Zukunft einfach, schnell und sicher
  deine Ernährung zu tracken. Erreiche deine Ziele und überlass nichts dem
  Zufall!
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      